import * as React from 'react';
import { css } from "@emotion/core";
import Carousel from 'nuka-carousel';

import CarouselButtonLeft from '../carouselButtons/CarouselButtonLeft';
import CarouselButtonRight from '../carouselButtons/CarouselButtonRight';
import PlanCardTrainer from './PlanCardTrainer';
import { renameKeys } from '../../utils/plans';
import { IAllFitplanPlans, ITypes } from '../../types/sanity';

import './plan-grid.scss';

export interface Props {
  title: string;
  currentPlan?: string;
  allFitplanPlans: IAllFitplanPlans;
  planTypes: ITypes;
  initialSlideHeight?: number;
}

const PlanGrid: React.SFC<Props> = props => {
  const {initialSlideHeight = 400} = props;
  let plans = (props.allFitplanPlans && props.allFitplanPlans.edges) || [];
  const types = renameKeys(props.planTypes, true);

  plans = plans.filter(({ node }) => props.currentPlan !== node.planName);
  if (plans.length) {
    return (
      <div className="plans__grid">
        <div className="plans__grid-title">{props.title}</div>

        <div className="plans__card-container">
          {plans.length < 4 ? (
            plans.map(({ node }) => {
              const { planName, planType, url, planImage } = node;
              const type = types[planType];
              return (
                <div
                  key={planName}
                  css={css`
                    width: 30%;
                  `}
                >
                  <PlanCardTrainer
                    src={planImage}
                    type={type}
                    planName={planName}
                    url={url}
                  />
                </div>
              );
            })
          ) : (
            <Carousel
              enableKeyboardControls={false}
              slidesToShow={4}
              cellAlign="center"
              wrapAround
              initialSlideHeight={initialSlideHeight}
              renderCenterLeftControls={({ previousSlide }) => (
                <CarouselButtonLeft
                  previousSlide={previousSlide}
                  className="plans__card-carousel-left"
                />
              )}
              renderCenterRightControls={({ nextSlide }) => (
                <CarouselButtonRight
                  nextSlide={nextSlide}
                  className="plans__card-carousel-right"
                />
              )}
              renderBottomCenterControls={() => null}
            >
              {plans.length > 5
                ? plans.map(({ node }) => {
                    const { planName, planType, url, planImage } = node;
                    const type = types[planType];

                    return (
                      <PlanCardTrainer
                        key={planName}
                        src={planImage}
                        type={type}
                        planName={planName}
                        url={url}
                      />
                    );
                  })
                : [...plans, ...plans].map(({ node }) => {
                    const { planName, planType, url, planImage } = node;
                    const type = types[planType];
                    return (
                      <PlanCardTrainer
                        key={planName}
                        src={planImage}
                        type={type}
                        planName={planName}
                        url={url}
                      />
                    );
                  })}
            </Carousel>
          )}
        </div>
        <div
          css={css`
            width: 210%;
            @media (min-width: 599px) {
              width: 120%;
            }
            @media (min-width: 900px) {
              display: none;
            }
          `}
        >
          {plans.length > 1 ? (
            <Carousel
              enableKeyboardControls={false}
              slidesToShow={3}
              wrapAround
              initialSlideHeight={props.initialSlideHeight}
              renderCenterLeftControls={({ previousSlide }) => (
                <CarouselButtonLeft
                  previousSlide={previousSlide}
                  className="plans__card-carousel-left"
                />
              )}
              renderCenterRightControls={({ nextSlide }) => (
                <CarouselButtonRight
                  nextSlide={nextSlide}
                  className="plans__card-carousel-right"
                />
              )}
              renderBottomCenterControls={() => null}
            >
              {/* unfortunately needs to be 4 here because the way nuka carousel works for the wrapAround feature to work properly is to have at least +1 image with regards to the number of image to display (i.e. here 3) */}
              {plans.length < 4
                ? [...plans, ...plans].map(({ node }) => {
                    const { planName, planType, url, planImage } = node;
                    const type = types[planType];
                    return (
                      <PlanCardTrainer
                        key={planName}
                        src={planImage}
                        type={type}
                        planName={planName}
                        url={url}
                      />
                    );
                  })
                : plans.map(({ node }) => {
                    const { planName, planType, url, planImage } = node;
                    const type = types[planType];
                    return (
                      <PlanCardTrainer
                        key={planName}
                        src={planImage}
                        type={type}
                        planName={planName}
                        url={url}
                      />
                    );
                  })}
            </Carousel>
          ) : (
            <div
              css={css`
                width: 33%;
                margin: auto;
              `}
            >
              {plans.map(({ node }) => {
                const { planName, planType, url, planImage } = node;
                const type = types[planType];
                return (
                  <PlanCardTrainer
                    key={planName}
                    src={planImage}
                    type={type}
                    planName={planName}
                    url={url}
                  />
                );
              })}
            </div>
          )}
        </div>
      </div>
    );
  }
  return <div />;
};

PlanGrid.defaultProps = {
  initialSlideHeight: 400,
};

export default PlanGrid;
