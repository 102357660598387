import * as React from "react";

import "./about-trainer.scss";

export interface Props {
    component: string;
    title: string;
    src: string;
    description: string;
}

const AboutTrainer: React.SFC<Props> = props => {
    return (
        <div className={`trainer__about ${props.component}-color`}>
            <h2 className="trainer__about-title">{props.title}</h2>
            <div className="trainer__about-block">
                <div className="trainer__about-image">
                    <div className="trainer__about-image-crop">
                        <img src={props.src} style={{ width: "100%" }} />
                    </div>
                </div>
                <div className="trainer__about-description">
                    {props.description}
                </div>
            </div>
        </div>
    );
};
export default AboutTrainer;
