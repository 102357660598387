import * as React from "react";
import { graphql } from "gatsby";
import { StaticQuery } from "gatsby";

import { renameKeys, getGoalSection } from "../../utils/plans";
import { localeTextGetter } from "../../utils/l10n";
import { WebImage } from "@fitplan/lib/components/WebImage";

import "./about-plan.scss";
import { imageBaseUrl } from "../../config";

const capitalize = (str: string): string =>
    str.charAt(0).toUpperCase() + str.slice(1);

const AboutPlanRoot: React.SFC<any> = props => {
    const { plan, commitment, about, goals, planTypes } = props;
    const levels = renameKeys(about.levels);
    const types = renameKeys(planTypes, true);
    const level = capitalize(levels[plan.level] || "all users");
    const type = types[plan.planType];
    if(plan.maleBulkUp) {
        plan.maleBulkup = true;
    }
    const goalList = getGoalSection(plan, goals.goalMap);

    return (
        <div className="plan__about">
            <div className="plan__about-commitment">
                <WebImage
                    className="plan__about-image plan__about-image__center"
                    src={`${imageBaseUrl}/web/plans/icons/calendar.png`}
                    alt="green calendar"
                    transformation={{ width: 64 }}
                />
                <h2 className="plan__about-title">{commitment.title}</h2>
                <div className="plan__about-text">
                    <strong>{plan.daysCount}</strong>
                    {` ${commitment.duration}`}
                </div>
                <div className="plan__about-text">
                    {commitment.daysPerWeek[0]}
                    <strong>{` ${plan.daysPerWeek}${commitment.daysPerWeek[1]}`}</strong>
                </div>
                <div className="plan__about-text">
                    <strong>{plan.workoutDuration}</strong> {commitment.length}
                </div>
            </div>
            <div className="plan__about-about">
                <WebImage
                    className="plan__about-image plan__about-image__center"
                    src={`${imageBaseUrl}/web/plans/icons/phone-heart.png`}
                    alt="green calendar"
                    transformation={{ width: 64 }}
                />
                <h2 className="plan__about-title">{about.aboutTitle}</h2>
                <div className="plan__about-text">{level}</div>
                <div className="plan__about-text">{type}</div>
            </div>
            <div className="plan__about-goal">
                <WebImage
                    className="plan__about-image plan__about-image__center"
                    src={`${imageBaseUrl}/web/plans/icons/deadlift.png`}
                    alt="green calendar"
                    transformation={{ width: 64 }}
                />
                <h2 className="plan__about-title">{goals.goalTitle}</h2>
                {goalList.map((goal: any) => (
                    <div key={goal} className="plan__about-text">
                        {capitalize(goal)}
                    </div>
                ))}
            </div>
        </div>
    );
};

const AboutPlan: React.SFC<any> = props => (
    <StaticQuery
        query={graphql`
            query aboutPlanQuery {
                planTypes {
                    num0
                    num1
                    num2
                    num3
                    num4
                    num5
                    num6
                    num7
                    num8
                    num9
                    num10
                    num11
                    num12
                    num13
                    num14
                    num15
                    num16
                    num17
                }
                sanityPlanDetail {
                    aboutPlan {
                        commitment {
                            title {
                                _type
                                en
                                es
                            }
                            duration {
                                _type
                                en
                                es
                            }
                            length {
                                _type
                                en
                                es
                            }
                            daysPerWeek {
                                _type
                                en
                                es
                            }
                        }
                        about {
                            aboutTitle {
                                _type
                                en
                                es
                            }
                            levels {
                                zero {
                                    _type
                                    en
                                    es
                                }
                                one {
                                    _type
                                    en
                                    es
                                }
                            }
                            types {
                                one {
                                    _type
                                    en
                                    es
                                }
                                two {
                                    _type
                                    en
                                    es
                                }
                                three {
                                    _type
                                    en
                                    es
                                }
                                four {
                                    _type
                                    en
                                    es
                                }
                                five {
                                    _type
                                    en
                                    es
                                }
                                six {
                                    _type
                                    en
                                    es
                                }
                                seven {
                                    _type
                                    en
                                    es
                                }
                            }
                        }
                        goals {
                            goalTitle {
                                _type
                                en
                                es
                            }
                            goalMap {
                                femaleBuildAndBurn {
                                    _type
                                    en
                                    es
                                }
                                femaleShredFat {
                                    _type
                                    en
                                    es
                                }
                                femaleToneAndTighten {
                                    _type
                                    en
                                    es
                                }
                                femaleBootyGains {
                                    _type
                                    en
                                    es
                                }
                                maleAthleticPerformance {
                                    _type
                                    en
                                    es
                                }
                                maleBulkup {
                                    _type
                                    en
                                    es
                                }
                                maleShredFat {
                                    _type
                                    en
                                    es
                                }
                            }
                        }
                    }
                }
            }
        `}
        render={data => {
            const {
                planTypes,
                sanityPlanDetail: {
                    aboutPlan: { commitment, about, goals },
                },
            } = localeTextGetter(data);
            const { planName } = props.plan;
            const plan = props.plan;
            return (
                <AboutPlanRoot
                    {...props}
                    plan={plan}
                    commitment={commitment}
                    about={about}
                    goals={goals}
                    planTypes={planTypes}
                />
            );
        }}
    />
);

export default AboutPlan;
