import * as React from 'react';
import Helmet from 'react-helmet';
import { graphql } from 'gatsby';
import localize from '../hoc/Localize';
import {Link} from "@fitplan/lib/components/Link";
import {ChevronBanner} from "@fitplan/lib/components/ChevronBanner";
import { Polygon } from "@fitplan/lib/components/Chevron/Polygon";

import PageWrapper from "../components/pageWrapper/PageWrapper";
import PlanGrid from "../components/planGrid";
import AboutTrainer from "../components/trainers/AboutTrainer";
import AboutPlan from "../components/aboutPlan";
import { FbPixel } from "../components/trackers/FbPixel";
import { Subscribe } from "../components/subscribe";
import { getGoalsList, goalsLine } from '../utils/plans';
import { getAthleteName } from '../utils/helpers';

import './plan.scss';

const pluralizeName = name => `${name}${name.slice(-1) === 's' ? "'" : "'s"}`;

const createMetaData = (plan, goalMap) => {
  const { planName } = plan;
  const name = getAthleteName(plan);
  return {
    title: `${name} | ${planName} | Fitplan`,
    description: `Fitplan app gives you access to ${pluralizeName(
      name
    )} exact training methods. If your goal${goalsLine(
      plan,
      goalMap
    )}, then ${planName} is the Fitplan for you. Start your free trial today.`,
    keywords: [name, planName, ...getGoalsList(plan, goalMap)],
  };
};

class PlanPage extends React.Component {
  renderAboutFitplan = () => {
    const { aboutFitplan } = this.props.data.sanityPlanDetail;
    return aboutFitplan.bullets.map(b => (
      <div className="plan__app-bullet-container" key={b}>
        <img className="plan__app-check" src="/images/icons/check-bold.png" />
        <div className="plan__app-bullet">{b}</div>
      </div>
    ));
  };

  render() {
    const plan = this.props.data.fitplanPlans;
    const {
      sanityPlanDetail,
      fitplanAthletes,
      allFitplanPlans,
      planTypes,
    } = this.props.data;
    const { about } = sanityPlanDetail.aboutPlan;
    const { types } = about;
    const metaData = createMetaData(plan, sanityPlanDetail.goalMap);
    const name = getAthleteName(plan);
    let normalizedSlug = plan.slug.split('_')[0];
    normalizedSlug = (normalizedSlug === 'Body-x') ? 'body-x-es' : normalizedSlug; 
    return (
      <PageWrapper {...this.props}>
        <Helmet
          title={metaData.title}
          meta={[
            { name: 'description', content: metaData.description },
            { name: 'keywords', content: metaData.keywords.join(', ') },
          ]}
        />
        <FbPixel event="ViewContent" data={{ plan: plan.slug }} />
        <div className="plan">
          <ChevronBanner
            src={plan.planImage}
            title={plan.planName}
            subtitle={
              process.env.GATSBY_L18N_LOCALE === 'en' ? getAthleteName(plan, true) : `${name}`
            }
            button={sanityPlanDetail.trainButton}
            branchLink={plan.link}
          />
          <AboutPlan plan={plan} />
          <video
            className="plan__video"
            controls
            poster={plan.planVideoScreenshot}
          >
            <source src={plan.planVideo480} type="video/mp4" />
          </video>
          <div className="plan__app">
            <div className="plan__app-text">
              <h2 className="plan__app-title">
                {sanityPlanDetail.aboutFitplan.title}
              </h2>
              {this.renderAboutFitplan()}
              <Link to="https://fitplan.app.link/uqve1zYYhZ">
                <button className="plan__app-cta-button" width="220px">
                  {sanityPlanDetail.aboutFitplan.subscribeButton}
                </button>
              </Link>
            </div>
            <img
              className="plan__app-screenshot"
              src={`/images/plans/screenshots/${normalizedSlug}.jpg`}
              alt="app screenshot"
            />
          </div>
          <PlanGrid
            title={sanityPlanDetail.plansTitle}
            component="plan"
            allFitplanPlans={allFitplanPlans}
            types={types}
            currentPlan={plan.planName}
            planTypes={planTypes}
          />
          {fitplanAthletes && (
            <AboutTrainer
              src={fitplanAthletes.athleteImageWide}
              title={sanityPlanDetail.trainerTitle}
              description={plan.athleteDescription}
              button={sanityPlanDetail.trialButton}
              component="plans"
              athleteSlug={plan.athleteSlug}
              view={this.props.data.sanityTrainer.view}
            />
          )}
        </div>
        <div style={{background: "#3a3b3e"}}>
            <Polygon polygonColor="#1b1c1c" />
        </div>
        <Subscribe />
        <div style={{background: "#212121"}}>
            <Polygon polygonColor="#111213" borderColor="#00ffb3" />
        </div>
      </PageWrapper>
    );
  }
}

export const query = graphql`
  query fitplanPlanQuery($slug: String, $athleteSlug: String) {
    planTypes {
      num0
      num1
      num2
      num3
      num4
      num5
      num6
      num7
      num8
      num9
      num10
      num11
      num12
      num13
      num14
      num15
      num16
      num17
    }
    sanityPlanDetail {
      aboutPlan {
        about {
          levels {
            zero {
              _type
              en
              es
            }
            one {
              _type
              en
              es
            }
          }
          types {
            zero {
              _type
              en
              es
            }
            one {
              _type
              en
              es
            }
            two {
              _type
              en
              es
            }
            three {
              _type
              en
              es
            }
            four {
              _type
              en
              es
            }
            five {
              _type
              en
              es
            }
            six {
              _type
              en
              es
            }
            seven {
              _type
              en
              es
            }
            eight {
              _type
              en
              es
            }
            nine {
              _type
              en
              es
            }
            ten {
              _type
              en
              es
            }
            eleven {
              _type
              en
              es
            }
            twelve {
              _type
              en
              es
            }
            thirteen {
              _type
              en
              es
            }
            fourteen {
              _type
              en
              es
            }
            fifteen {
              _type
              en
              es
            }
            sixteen {
              _type
              en
              es
            }
            seventeen {
              _type
              en
              es
            }
          }
        }
      }
      goalMap {
        femaleBuildAndBurn {
          _type
          en
          es
        }
        femaleShredFat {
          _type
          en
          es
        }
        femaleToneAndTighten {
          _type
          en
          es
        }
        femaleBootyGains {
          _type
          en
          es
        }
        maleAthleticPerformance {
          _type
          en
          es
        }
        maleBulkup {
          _type
          en
          es
        }
        maleShredFat {
          _type
          en
          es
        }
      }
      aboutFitplan {
        title {
          _type
          en
          es
        }
        bullets {
          _type
          en
          es
        }
        subscribeButton {
          _type
          en
          es
        }
      }
      trainerTitle {
        _type
        en
        es
      }
      reviewsTitle {
        _type
        en
        es
      }
      plansTitle {
        _type
        en
        es
      }
      trainButton {
        _type
        en
        es
      }
      trialButton {
        _type
        en
        es
      }
    }
    sanityTrainer {
      view {
        _type
        en
        es
      }
    }
    fitplanPlans(slug: { eq: $slug }) {
      id
      url
      planImage
      link
      planName
      planType
      planVideoScreenshot
      planVideo480
      planVideo720
      planVideo1080
      femaleBootyGains
      femaleBuildAndBurn
      femaleShredFat
      femaleToneAndTighten
      maleAthleticPerformance
      maleBulkUp
      maleShredFat
      daysCount
      workoutDuration
      daysPerWeek
      level
      firstName
      lastName
      sex
      slug
      athleteImage
      athleteDescription
      athleteSlug
      athletePlans {
        id
        url
        name
        athleteFirstName
        athleteLastName
        sex
        slug
        goalFlag
        featured
        imageUrl
        daysCount
        femaleBootyGains
        femaleBuildAndBurn
        femaleShredFat
        femaleToneAndTighten
        maleAthleticPerformance
        maleBulkUp
        maleShredFat
        athleteSlug
      }
    }
    fitplanAthletes(slug: { eq: $athleteSlug }) {
      link
      athleteImageWide
    }
    allFitplanPlans(filter: { athleteSlug: { eq: $athleteSlug } }) {
      edges {
        node {
          url
          planName
          planType
          planImage
        }
      }
    }
  }
`;

export default localize(PlanPage);
