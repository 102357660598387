import React from "react";
import useMount from "react-use/lib/useMount";
import { useFbPixel } from "@fitplan/context/lib-es/analytics";

export interface Props {
    event: string;
    data?: any;
}

export const FbPixel: React.FunctionComponent<any> = ({event, data}) => {
    const fbPixel = useFbPixel();
    useMount(() => {
        console.log("FBPIXEL", event, data);
        fbPixel.track(event, data);
    });
    return (<span style={{display: "none"}} />);
}
