import * as React from "react";
import {Link} from "@fitplan/lib/components/Link";

import "./plan-card-trainer.scss";

export interface Props {
    url: string;
    planName: string;
    src: string;
    type: string;
}

const PlanCardTrainer: React.SFC<Props> = props => {
    return (
        <Link
            to={`/plans/${props.url}`}
            key={props.planName}
            className="plans__cardTrainer"
        >
            <div className="plans__cardTrainer-container">
                <img src={props.src} style={{ width: "100%" }} />
                <div className="plans__cardTrainer-text">
                    <div className="plans__cardTrainer-title">
                        {props.planName}
                    </div>
                    <div className="plans__cardTrainer-description">
                        {props.type}
                    </div>
                </div>
            </div>
        </Link>
    );
};

export default PlanCardTrainer;
